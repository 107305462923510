@use 'sass:color';

// variable names conventions
//
// x - multiplier
// sz - size
// c - colour
// st - style
// l - list


// z-incexes
$l-layers: (
  modal:                      1000,
  dropdown:                   500,
  popover:                    400,
  header:                     300,
  default:                    1,
  below:                      -1,
);


$x-leading:                     1.5;

$sz-rhythm:                     #{1.5 * $x-leading + 'em'};
$sz-corner:                     .2em;
$sz-container:                  1200px;
$sz-copywidth:                  25ch;

$c-white:                       #f0f0f0;
$c-black:                       #221e18;
$c-accent:                      #ffe8c6;

$c-app-body-bg:                 $c-black;
$c-app-body-txt:                $c-white;

$sz-grid-gap:                   .3em;
