@use '../config';
@use '../tools';

html,
body {
  margin: 0;
  padding: 0;
}

body {
  @extend %font--body;
  background-color: config.$c-app-body-bg;
  color: config.$c-app-body-txt;
}

a {
  color: config.$c-app-body-txt;

  &:visited {
    color: config.$c-app-body-txt;
  }

  &:hover,
  &:active {
    color: config.$c-accent;
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: #{config.$x-leading + 'em'};
  opacity: .7;
}
