@use '../config';
@use '../tools';

.app__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 30vh;
  gap: config.$sz-grid-gap;
  margin: config.$sz-grid-gap;

  @include tools.breakpoint(tablet-down) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 40vh;
  }

  @include tools.breakpoint(mobile-down) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

article {
  img,
  iframe {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
}

.large {
  grid-column: auto / span 2;
  grid-row: auto / span 2;

  @include tools.breakpoint(tablet-down) {
    grid-column: auto;
    grid-row: auto;
  }
}

.vertical {
  grid-row: auto / span 2;

  @include tools.breakpoint(tablet-down) {
    grid-row: auto;
  }
}

.separator {
  grid-column: 1 / -1;
}

.text {
  background: config.$c-white;
  color: config.$c-black;

  p {
    @extend %copy;
  }

  header {
    text-align: center;

    p {
      margin: .2em 0 0;
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  padding: config.$sz-rhythm;

  section {
    margin-top: auto;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: #{config.$x-leading + 'em'};

  p:only-child {
    margin: 0;
  }
}
