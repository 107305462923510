*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body {
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
}

img {
  display: inline-block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}
