@use 'sass:list';
@use 'sass:map';
@use '../config/breakpoints';


// Hugo Giraudel https://github.com/HugoGiraudel
// Create a single media condition out of a map of queries
@mixin media($queries) {
  @if list.length($queries) == 0 {
    @content;
  } @else {
    $first-key: list.nth(map.keys($queries), 1);

    @media ($first-key: map.get($queries, $first-key)) {
      $queries: map.remove($queries, $first-key);

      @include media($queries) {
        @content;
      }
    }
  }
}

@mixin breakpoint($breakpoint) {
  $queries: map.get(breakpoints.$breakpoints, $breakpoint);

  @if not $queries {
    @error 'The `#{$breakpoint}` is not defined in the map';
  }

  @include media($queries) {
    @content;
  }
}
