@use '../tools';
@use 'var';

@include tools.font-face('/assets/fonts/wellagain', v1, 'Wellagain', normal, normal, woff);
@include tools.font-face('/assets/fonts/source-sans', v1, 'Source Sans', normal, normal, woff2);
@include tools.font-face('/assets/fonts/source-sans-italic', v1, 'Source Sans', normal, italic, woff2);
@include tools.font-face('/assets/fonts/source-sans-bold', v1, 'Source Sans', bold, normal, woff2);
@include tools.font-face('/assets/fonts/source-sans-bold-italic', v1, 'Source Sans', bold, italic, woff2);

%font--body {
  font-family: "Source Sans", sans-serif;
}

%font--decor {
  font-family: 'Wellagain', Times, script;
}

.listless {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: .5em 0;
  }
}

h1 {
  @extend %font--decor;
  margin: 0;
  line-height: var.$x-leading;
  font-size: 5em;

  @include tools.breakpoint(desktop-down) {
    font-size: 3em;
  }
}

%copy {
  font-size: 1.2em;
  line-height: var.$x-leading;

  @include tools.breakpoint(desktop-down) {
    font-size: 1em;
  }

  p {
    margin: #{var.$x-leading + 'em'} 0;
    hanging-punctuation: first;
  }
}
